<template>
    <div class="text-left">
        <!-- <stock></stock> -->
        <div style="background-color: #ffffff;">
            <div class="pageCenter titleBar">
                <div class="titleBar_left">
                    <!-- <img @click="$oucy.replace('/', null)" style="height: 40px;cursor: pointer;" src="@/assets/logo.png"> -->
                    <div class="titleBar_left_txt">
                        【{{obj.schemeName}}】报价清单
                    </div>
                </div>
                <div class="titleBar_right">
                    <!-- 返回首页 -->
                    <el-popover
                      placement="right"
                      trigger="click">
                      <div ref="unionIdQrCodeUrl_1"></div>
                      <el-button size="mini" slot="reference" >生成手机版方案</el-button>
                    </el-popover>

                    <!-- <el-button type="" size="mini" @click="">生成手机版方案</el-button> -->
                    <el-button type="" class="m-l-10" size="mini" @click="exportExcel">导出EXCEL</el-button>
                </div>
            </div>
        </div>

        <div class="container">
            <div class="pageCenter">
                <el-container>
                    <div class="p-20" style="width: 98%">
<!--                        <div class="title c-1 f24 m-t-30">{{obj.schemeName}}</div>-->
                        <div class="box m-t-30">
                            <div class="left">
                                <div style="width:150px">
                                    <upload-image @uploadImageBack="uploadImageBack0" class="avatar-uploader" size="mini">
                                        <img v-if="objBrand.brandIcon" :src="$oucy.ossUrl+objBrand.brandIcon" class="avatar mini">
                                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                    </upload-image>
                                    <el-button type="text" size="mini" @click="openSetBrand" v-if="enterpriseId">选择已有品牌图片</el-button>
                                </div>
                            </div>
                            <div class="center">
                                <div class="f14 c-2">
                                    <div class="df m-t-10">
                                        <div class="l_left ">采购方</div>
                                        <div class="l_right line-1">
                                            <span class="c-1" v-if="objBuyer.buyerContactName">{{objBuyer.buyerContactName}}</span>
                                            <span v-else class="c-2">请先添加采购方信息</span>
                                        </div>
                                    </div>
                                    <div class="df m-t-10">
                                        <div class="l_left ">联系人</div>
                                        <div class="l_right line-1">
                                            <span class="c-1" v-if="objBuyer.buyerName">{{objBuyer.buyerName}}</span>
                                            <span v-else class="c-2">请先添加采购方信息</span>
                                        </div>
                                    </div>
                                    <div class="df m-t-10">
                                        <div class="l_left ">联系电话</div>
                                        <div class="l_right line-1">
                                            <span class="c-1" v-if="objBuyer.buyerContactPhone">{{objBuyer.buyerContactPhone}}</span>
                                            <span v-else class="c-2">请先添加采购方信息</span>
                                        </div>
                                    </div>
                                    <div class="df m-t-10">
                                        <el-button type="primary" size="mini" class="m-l-30" @click="openAddBuyer">添加采购方</el-button>
                                        <el-button type="primary" size="mini" @click="openSetBuyer">选择已有</el-button>
                                    </div>
                                </div>
                            </div>
                            <div class="right">
                                <div class="f14 c-2">
                                    <div class="df m-t-10">
                                        <div class="l_left ">供货方</div>
                                        <div class="l_right line-1">
                                            <span class="c-1" v-if="objSeller.sellerName">{{objSeller.sellerName}}</span>
                                            <span v-else class="c-2">请先添加供货方信息</span>
                                        </div>
                                    </div>
                                    <div class="df m-t-10">
                                        <div class="l_left ">联系人</div>
                                        <div class="l_right line-1">
                                            <span class="c-1" v-if="objSeller.sellerRealName">{{objSeller.sellerRealName}}</span>
                                            <span v-else class="c-2">请先添加供货方信息</span>
                                        </div>
                                    </div>
                                    <div class="df m-t-10">
                                        <div class="l_left ">联系电话</div>
                                        <div class="l_right line-1">
                                            <span class="c-1" v-if="objSeller.sellerPhone">{{objSeller.sellerPhone}}</span>
                                            <span v-else class="c-2">请先添加供货方信息</span>
                                        </div>
                                    </div>
                                    <div class="df m-t-10">
                                        <el-button type="primary" size="mini" class="m-l-30" @click="openAddSeller">添加供货方</el-button>
                                        <el-button type="primary" size="mini" @click="openSetSeller">选择已有供货方</el-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- list:{{list}} -->
                        <template>
                            <el-table :data="list" border style="width: 100%" class="m-t-20">
                                <el-table-column type="index" label="序号" width="50px"></el-table-column>
                                
                                <el-table-column prop="date" label="产品图片" width="80px">
                                    <div slot-scope="scope">
                                        <!-- <video v-if="scope.row.spuVideo" :src="$oucy.ossUrl+scope.row.spuVideo" style="width:40px;height: 40px;background: #f1f1f1;" controls="1"></video> -->
                                        <!-- <div v-else> -->
                                        <el-avatar shape="square" :src="scope.row.itemAvata" v-if="hasImportImg(scope.row.itemAvata)"></el-avatar>
                                        <el-avatar shape="square" :src="$oucy.ossUrl+scope.row.itemAvata" v-else></el-avatar>
                                        <!-- </div> -->
                                    </div>
                                </el-table-column>
                                <el-table-column prop="itemName" label="产品名称">
                                </el-table-column>
                                <el-table-column prop="itemAttrDesc" label="产品规格">
                                </el-table-column>
                                <el-table-column prop="itemCount" label="数量" width="60px">
                                </el-table-column>
                                <el-table-column prop="spuPrise" label="价格" width="150px">
                                    <template slot-scope="scope">
                                        <div v-if="scope.row.itemNumberPrise">
                                            <div>原价：￥{{scope.row.itemPrise}}</div>
                                            <div class="c-m">会员价：￥{{scope.row.itemNumberPrise}}</div>
                                        </div>
                                        <div v-else>
                                            <span class="spuPrise" v-if="scope.row.itemAdjustPrise=='0.00'">面议</span>
                                            <span class="spuPrise" v-else>￥{{scope.row.itemAdjustPrise}}</span>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="isShelves" label="备注" v-if="0">
                                    <template slot-scope="scope">
                                        <div>2021-06-16 00:00:00</div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </template>
                        <div class="df f14 c-1 m-t-20">
                            <div class="m-r-20">产品数量：<span class="blod">{{num}}</span></div>
                            <div class="m-r-30">报价清单总额(RMB)：<span class="blod">{{obj.schemePrise}}</span></div>
                            <div class="m-r-350">实收金额(RMB)：<span class="blod">{{obj.schemeAdjustPrise}}</span></div>
                            <div>大写金额(RMB)：<span class="blod">{{$oucy.ToString(obj.schemeAdjustPrise)}}</span></div>
                        </div>
                        <div class="text-center m-t-20">
                            <el-button type="primary" @click="" size="mini" @click="submit">预览报价清单</el-button>
                        </div>
                    </div>
                </el-container>
            </div>
        </div>
        <!-- 供货方 -->
        <!-- 供货方 -->
        <!-- 供货方 -->
        <!-- 添加供货方 -->
        <el-dialog :title="ruleSeller.id?'编辑供货方':'添加供货方'" :visible.sync="dialogVisibleAddSeller" width="500px" :before-close="handleClose">
            <!-- <span>这是一段信息</span> -->
            <el-form :model="ruleSeller" :rules="rulesSeller" ref="ruleSeller" label-width="100px" class="demo-ruleSeller">
                <el-form-item label="头像" prop="sellerAvata">
                    <upload-image @uploadImageBack="uploadImageBack" class="avatar-uploader" size="mini">
                        <img v-if="ruleSeller.sellerAvata" :src="$oucy.ossUrl+ruleSeller.sellerAvata" class="avatar mini">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </upload-image>
                    <el-checkbox v-model="ruleSeller.sellerDefault">是否默认</el-checkbox>
                </el-form-item>
                <el-form-item label="供货方名称" prop="sellerName">
                    <el-input v-model="ruleSeller.sellerName"></el-input>
                </el-form-item>
                <el-form-item label="联系人" prop="sellerRealName">
                    <el-input v-model="ruleSeller.sellerRealName"></el-input>
                </el-form-item>
                <el-form-item label="联系电话" prop="sellerPhone">
                    <el-input v-model="ruleSeller.sellerPhone"></el-input>
                </el-form-item>
                <el-form-item label="二维码名片" prop="sellerQrcode">
                    <upload-image @uploadImageBack="uploadImageBack2" class="avatar-uploader" size="mini">
                        <img v-if="ruleSeller.sellerQrcode" :src="$oucy.ossUrl+ruleSeller.sellerQrcode" class="avatar mini">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </upload-image>
                </el-form-item>
                <el-form-item>
                    <template>
                      <!-- `checked` 为 true 或 false -->
                      <el-checkbox v-model="more">填写更多</el-checkbox>
                    </template>
                </el-form-item>
                <!-- <el-form-item label="公司门店" prop="sellerCompany"> -->
                <el-form-item label="公司门店" prop="" v-if="more">
                    <el-input v-model="ruleSeller.sellerCompany"></el-input>
                </el-form-item>
                <!-- <el-form-item label="职务" prop="sellerPosition"> -->
                <el-form-item label="职务" prop="" v-if="more">
                    <el-input v-model="ruleSeller.sellerPosition"></el-input>
                </el-form-item>
                <!-- <el-form-item label="邮箱" prop="sellerEmail"> -->
                <el-form-item label="邮箱" prop="" v-if="more">
                    <el-input v-model="ruleSeller.sellerEmail"></el-input>
                </el-form-item>
                <!-- <el-form-item label="详细地址" prop="sellerAddr"> -->
                <el-form-item label="详细地址" prop="" v-if="more">
                    <el-input type="textarea" v-model="ruleSeller.sellerAddr" maxlength="120" show-word-limit></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisibleAddSeller = false">取 消</el-button>
                <el-button type="primary" @click="submitForm">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 选择已有供货方 -->
        <el-dialog width="800px" title="选择已有供货方" :visible.sync="dialogVisibleSetSeller">
            <template>
                <el-table :data="prlductData.content" style="width: 100%;" height="400">
                    <!-- <el-table-column type="index" label="编号"></el-table-column> -->
                    <el-table-column prop="" label="头像" width="100">
                        <template slot-scope="scope">
                            <el-avatar shape="square" :src="scope.row.sellerAvata" v-if="hasImportImg(scope.row.sellerAvata)"></el-avatar>
                            <el-avatar shape="square" :src="$oucy.ossUrl+scope.row.sellerAvata" v-else></el-avatar>
                        </template>
                    </el-table-column>
                    <el-table-column prop="sellerName" show-overflow-tooltip label="供货方名称"></el-table-column>
                    <el-table-column prop="sellerRealName" show-overflow-tooltip label="联系人"></el-table-column>
                    <el-table-column prop="sellerAddr" show-overflow-tooltip label="详细地址"></el-table-column>
                    </el-table-column>
                    <el-table-column label="操作" width="220">
                        <template slot-scope="scope">
                            <el-button type="primary" size="mini" @click="setSeller(scope.row,scope.index)">选择</el-button>
                            <el-button type="default" size="mini" @click="updateSeller(scope.row,scope.index)">修改</el-button>
                            <el-button type="danger" size="mini" @click="delContactSeller(scope.row,scope.index)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination class="m-t-10" v-if="prlductData" background layout="sizes, prev, pager, next, jumper, ->, total, slot" :page-size="prlductData.size" :total="prlductData.totalElements" @size-change="sizeChangeSeller" @current-change="currentChangeSeller" @prev-click="currentChangeSeller" @next-click="currentChangeSeller">
                </el-pagination>
            </template>
        </el-dialog>
        <!-- 采购方  -->
        <!-- 采购方  -->
        <!-- 采购方  -->
        <!-- 添加/编辑采购方 -->
        <el-dialog title="添加/编辑采购方" :visible.sync="dialogVisibleAddBuyer" width="500px" :before-close="handleClose">
            <!-- <span>这是一段信息</span> -->
            <el-form :model="ruleBuyer" :rules="rulesBuyer" ref="ruleBuyer" label-width="100px" class="demo-ruleBuyer">
                <el-form-item label="采购方" prop="buyerName">
                    <el-input v-model="ruleBuyer.buyerName"></el-input>
                </el-form-item>
                <el-form-item label="联系人" prop="buyerContactName">
                    <el-input v-model="ruleBuyer.buyerContactName"></el-input>
                </el-form-item>
                <el-form-item label="联系电话" prop="buyerContactPhone">
                    <el-input v-model="ruleBuyer.buyerContactPhone"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisibleAddBuyer = false">取 消</el-button>
                <el-button type="primary" @click="submitForm2">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 选择已有采购方 -->
        <el-dialog width="800px" title="选择已有采购方" :visible.sync="dialogVisibleSetBuyer">
            <template>
                <el-table :data="contactBuyer.content" style="width: 100%;" height="400">
                    <el-table-column prop="buyerName" label="采购方名称" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="buyerContactName" label="联系人" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="buyerContactPhone" label="联系电话" show-overflow-tooltip></el-table-column>
                    </el-table-column>
                    <el-table-column label="操作" width="220">
                        <template slot-scope="scope">
                            <el-button type="primary" size="mini" @click="setBuyer(scope.row,scope.index)">选择</el-button>
                            <el-button type="default" size="mini" @click="updateBuyer(scope.row,scope.index)">修改</el-button>
                            <el-button type="danger" size="mini" @click="delContactBuyer(scope.row,scope.index)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination class="m-t-10" v-if="contactBuyer" background layout="sizes, prev, pager, next, jumper, ->, total, slot" :page-size="contactBuyer.size" :total="contactBuyer.totalElements" @size-change="sizeChange" @current-change="currentChange" @prev-click="currentChange" @next-click="currentChange">
                </el-pagination>
            </template>
        </el-dialog>
        <!-- 选择品牌 -->
        <el-dialog width="600px" title="选择已有品牌" :visible.sync="dialogVisibleSetBrands">
            <template>
                <div class="UserBrands">
                    <div v-for="(v,i) of UserBrands" class="UserBrand" :key="i" @click="objBrandCache=v" :class="{'select':objBrandCache.id==v.id}">
                        <img :src="$oucy.ossUrl + v.brandIcon" alt="" class="Brand">
                        <img src="@/assets/images/stockCar/corner.png" class="corner">
                    </div>
                </div>
            </template>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisibleSetBrands = false">取 消</el-button>
                <el-button type="primary" @click="submitForm3">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import offer from './offer.js'
export default offer
</script>
<style lang="less" scoped>
@import "./offer.less";
</style>